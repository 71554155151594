<template>
    <div class="main pay">
        <simple-header :userInfo="userInfo" />
        <pay-logo :process="false" />
        <section v-if="!showResult" class="pay-item mt20">
            <h3 class="pay-title">订单提交成功</h3>
            <p class="pay-txt">您的订单提交成功，请尽快付款！订单号：{{ orderId }}</p>
            <p class="pay-txt pay-price-wrapper">
                <label for="">待支付金额</label>
                <strong class="pay-price">￥{{ money }}</strong>
                <i>元</i>
            </p>
        </section>
        <section v-if="!showResult" class="pay-item">
            <p class="pay-txt-strong">{{ payMethodTxt[Number(type) - 1] }}支付</p>
            <div class="pay-main">
                <p class="pay-tips">距离二维码过期还剩 {{ countDownNum }} 秒，过期后请刷新页面重新获取二维码。</p>
                <div id="wechatQrCode" class="pay-qrcode">
                    <!-- <img :src="require('@a/img/qrcode.png')"> -->
                </div>
                <p class="pay-qrcode-tips">请使用{{ payMethodTxt[Number(type) - 1] }}扫一扫<br>扫描二维码支付</p>
                <router-link :to="{ path: '/payment', query: { orderid: orderId, money }}" class="btn-primary btn-round btn-plain">&#60;&nbsp;&nbsp;选择其他支付方式</router-link>
            </div>
        </section>
        <section v-if="showResult" class="pay-item pay-result mt20">
            <h3 class="pay-title">{{ payResultType[payResultIndex].title }}</h3>
            <p class="pay-txt">{{ payResultType[payResultIndex].content }}</p>
            <router-link to="/my/order/1" class="btn-primary btn-round btn-plain">&#60;&nbsp;&nbsp;返回订单列表</router-link>
        </section>
        <simple-footer />
    </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import simpleHeader from '@c/simpleHeader.vue'
import simpleFooter from '@c/simpleFooter.vue'
import payLogo from './payLogo.vue'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { getOrderPayStatus } from '@api/order'
export default {
    name: 'PaymentMethod',
    mixins: [ mixin, authMixin ],
    components: {
        simpleHeader,
        simpleFooter,
        payLogo
    },
    data() {
        return {
            orderId: '',
            money: '',
            type: 1, // 1:微信支付；2:支付宝
            // orderstatus: '', // 3为尾款支付
            // isuseIntergal: 0,
            // payKC: false,
            codeurl: '',
            payorderid: '',
            payMethodTxt: ['微信', '支付宝'],
            showResult: false, // 是否显示支付成功或者失败信息
            payResultIndex: 0,
            payResultType: [
                { title: '支付成功！', content: '您的支付成功，支付流水号：113904086152' },
                { title: '支付失败！', content: '您的本次支付失败，如已支付货款，请等待支付宝/微信自动退款。' }
            ],
            countDownNum: 600,
            timer: null,
            timeout: 600000 // 支付请求超时间10分钟
        }
    },
    methods: {
        // 20秒倒计时
        countDown() {
            const self = this
            window.setInterval(() => {
                if (self.countDownNum > 0) self.countDownNum--
                else location.reload(true)
            }, 1000)
        },
        getParamsData() {
            console.log('query data', queryData)
            const queryData = this.$route.query
            if (this.$route.query) {
                this.orderId = queryData['orderid']
                this.money = queryData['money']
                this.type = queryData['type']
                // this.orderstatus = queryData['orderstatus']
                // this.payKC = queryData['paykc']
                // this.orderPays()
                this.codeurl = queryData['codeurl']
                this.payorderid = queryData['payorderid']
                this.getQrCode()
                // 倒计时
                this.countDown()
                this.getOrderPayStatus()
            }
        },
        // orderid （类似 ["20200610100991028", "20200610100100480"]）, money（总金额），type（1微信2支付宝），isuse_intergal（1 使用用户积分 0不使用）
        // async orderPays() {
        //     const params = {
        //         orderid: this.orderId,
        //         money: this.money,
        //         type: this.type,
        //         // isuse_intergal: this.isuseIntergal
        //         isuse_intergal: this.payKC ? 1 : 0 // 0不使用，1使用
        //     }
        //     // this.orderstatus === 3 为尾款支付，需调用orderPaye接口
        //     const res = this.orderstatus === 3 ? await orderPaye(params) : await orderPays(params)
        //     console.log('orderPays res', res)
        //     const resDetail = res.detail
        //     if (resDetail.status === 1) {
        //         if (resDetail && resDetail.code_url) {
        //             this.getQrCode(resDetail.code_url)
        //             // 倒计时
        //             this.countDown()
        //             this.getOrderPayStatus(resDetail.payorderid)
        //         }
        //     } else if(resDetail.status === 99) {
        //         this.$notify({ title: '提示', type: 'error', message: '系统繁忙，请稍后再试', position: 'bottom-right' })
        //     } else if(resDetail.status === 1001) {
        //         this.$notify({ title: '提示', type: 'error', message: '积分抵扣成功', position: 'bottom-right' })
        //     } else {
        //         this.$notify({ title: '提示', type: 'error', message: '有异常订单不能支付', position: 'bottom-right' })
        //     }
        // },
        // 微信生成二维码
        getQrCode() {
            // 微信生成二维码
            this.$nextTick(_ => {
                if(document.getElementById('wechatQrCode')) {
                    const wechatQrContent = document.getElementById('wechatQrCode').innerHTML
                    if (wechatQrContent === '') {
                        const wechatQrCode = new QRCode('wechatQrCode', {
                            text: this.codeurl,
                            width: 280,
                            height: 280,
                            colorDark: '#000000',
                            colorLight: '#ffffff',
                            correctLevel: QRCode.CorrectLevel.H
                        })
                    }
                }
            })
        },
        // 查看支付情况,必传: payorderid
        getOrderPayStatus() {
            const self = this
            window.clearInterval(this.timer)
            // 轮询检查订单是否已支付，支付后跳转
            this.timer = window.setInterval(async() => {
                const res = await getOrderPayStatus({ payorderid: this.payorderid })
                const resDetail = res.detail
                if (resDetail && resDetail.status === 1) {
                    window.clearInterval(this.timer)
                    this.$router.push({ path: '/payment-result', query: { type: 0, payorderid: this.payorderid }})
                }
            }, 2000)
            // 设置超时退出到首页
            setTimeout(function() {
                self.showResult = true
                self.payResultIndex = 1
            }, self.timeout)
        }
    },
    created() {
        this.getAccount()
    },
    beforeRouteEnter(to, from, next) {
        // console.log('from, to', from, to)
        next(vm => {
            vm.getParamsData()
        })
    },
    beforeRouteLeave(to, from, next) {
        window.clearInterval(this.timer)
        next()
    }
}
</script>
<style lang="scss" scoped>
.pay-item {
    position: relative;
    width: 1200px;
    min-height: 60px;
    padding: 20px;
    margin: 10px auto 20px;
    background-color: $white;
    &.mt20 {
        margin-top: 20px;
    }
}
.pay-title {
    margin-bottom: 10px;
    font-size: 18px;
}
.pay-txt {
    color: $light;
}
.pay-price {
    margin-bottom: 10px;
    font-size: 20px;
    color: $red;
}
.pay-txt-strong {
    margin-bottom: 10px;
    font-size: 14px;
}
.pay-price-wrapper {
    position: absolute;
    top: 30px;
    right: 20px;
    font-size: 14px;
    .pay-price {
        padding: 0 10px;
    }
}
.pay-main {
    width: 360px;
    margin: 0 auto;
    text-align: center;
    .btn-primary {
        display: block;
        width: 300px;
        margin: 0 auto;
    }
}
.pay-tips {
    margin-bottom: 15px;
    white-space: nowrap;
    color: $light;
}
.pay-qrcode {
    @include widthHeight(300px, 300px);
    padding: 10px;
    margin: 0 auto;
    border: 1px solid $gray;
    > img {
        display: block;
        width: 100%;
        height: 100%;
    }
}
.pay-qrcode-tips {
    width: 300px;
    padding: 12px 20px;
    margin: 10px auto;
    line-height: 1.5;
    color: $white;
    background-color: $redlight;
}
.pay-result {
    .pay-txt {
        margin-bottom: 10px;
    }
    .btn-primary {
        width: 182px;
    }
}
</style>
