import Http from './http'
// import Qs from 'querystring'
// 1.必传: info 类似 一个id 一个数量
// info=[{"commodity_details_id":54,"num":1},{"commodity_details_id":37,"num":1},{"commodity_details_id":50,"num":1},{"commodity_details_id":52,"num":3},{"commodity_details_id":51,"num":2}]
export function getOrderInfo(params) {
    return Http({
        // url: `/order/getOrderInfo?info=${JSON.stringify(data)}`,
        url: '/order/getOrderInfo',
        method: 'get',
        params
    })
}
// 2.获取运费价格,必传: commodity_details_id ,  provincecode
export function getFare(params) {
    return Http({
        url: '/order/getFare',
        method: 'get',
        params
    })
}
// 3.下单，必传: users_address_id ，
// info （类似 info=[{"commodity_details_id":54,"num":1},{"commodity_details_id":52,"num":2}]）
// num数量
export function addOrder(params) {
    return Http({
        url: '/order/addOrder',
        method: 'get',
        params
    })
}
// 4.发起支付，必传: orderid （类似 ["20200610100991028", "20200610100100480"]）,  money（总金额），type（1微信2支付宝），isuse_intergal（1 使用用户积分 0不使用）
export function orderPays(params) {
    return Http({
        url: '/order/orderPays',
        method: 'post',
        params
    })
}
// 5 查看支付情况,必传: payorderid
export function getOrderPayStatus(params) {
    return Http({
        url: '/order/getOrderPayStatus',
        method: 'get',
        params
    })
}
// 6.追加尾款支付，必传: orderid （类似 ["20200610100991028", "20200610100100480"]）,  money（总金额），type（1微信2支付宝），isuse_intergal（1 使用用户积分 0不使用）
export function orderPaye(params) {
    return Http({
        url: '/order/orderPaye',
        method: 'post',
        params
    })
}
// 7.order/checkOrderStatus?orderid=["20201124559910241"]&money=0.01   检查订单状态 
export function checkOrderStatus(params) {
    return Http({
        url: '/order/checkOrderStatus',
        method: 'get',
        params
    })
}
// 8.order/checkOrderEndStatus?orderid=["20201126989953100"]&money=99.99   检查尾款支付订单状态 
export function checkOrderEndStatus(params) {
    return Http({
        url: '/order/checkOrderEndStatus',
        method: 'get',
        params
    })
}
// 9.获取用户K点（登录之后调用）
export function getUserIntegral(params) {
    return Http({
        url: '/userinfo/getUserIntegral',
        method: 'get',
        params
    })
}
// 10.检查订单类型status =1 是正常情况 paymoney是现在要支付的钱 isintegral =0 使用k点 抵扣那句话显示  如果isintegral=1  那句话就去掉
export function checkOrderType(params) {
    return Http({
        url: '/order/checkOrderType',
        method: 'get',
        params
    })
}
