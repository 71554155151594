<template>
    <div class="simple-logo order-logo">
        <div class="simple-logo-box">
            <img class="simple-logo-img" src="@a/img/login-logo.png" width="128" height="50" alt="kamisoul">
            <h3 class="simple-title">收银台</h3>
        </div>
        <section v-if="process" class="step-wrapper">
            <page-step class="step-number" :step-data="stepData" />
        </section>
    </div>
</template>
<script>
import pageStep from '@c/pageStep.vue'
export default {
    name: 'OrderLogo',
    components: {
        pageStep
    },
    props: {
        process: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            stepData: [
                { up: '', down: '1.填写核对订单信息', active: true },
                { up: '', down: '2.成功提交订单', active: true }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.order-logo {
    position: relative;
    height: auto;
}
.step-wrapper {
    position: absolute;
    top: 20px;
    left: 50%;
    margin-left: 180px;
    width: 410px;
}
.order-title {
    padding: 40px 0 20px;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
}
</style>
