import { render, staticRenderFns } from "./payLogo.vue?vue&type=template&id=a39b5104&scoped=true&"
import script from "./payLogo.vue?vue&type=script&lang=js&"
export * from "./payLogo.vue?vue&type=script&lang=js&"
import style0 from "./payLogo.vue?vue&type=style&index=0&id=a39b5104&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a39b5104",
  null
  
)

export default component.exports