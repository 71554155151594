<template>
    <div class="main pay">
        <simple-header :userInfo="userInfo" />
        <pay-logo :process="false" />
        <section class="pay-item pay-result mt20">
            <h3 class="pay-title">{{ payResultType[payResultIndex].title }}</h3>
            <p class="pay-txt">{{ payResultType[payResultIndex].content }}</p>
            <router-link to="/my/order/1" class="btn-primary btn-round btn-plain">&#60;&nbsp;&nbsp;返回订单列表</router-link>
        </section>
        <simple-footer />
    </div>
</template>
<script>
import simpleHeader from '@c/simpleHeader.vue'
import simpleFooter from '@c/simpleFooter.vue'
import payLogo from './payLogo.vue'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
export default {
    name: 'PaymentResult',
    mixins: [ mixin, authMixin ],
    components: {
        simpleHeader,
        simpleFooter,
        payLogo
    },
    data() {
        return {
            payResultIndex: 0,
            payResultType: [
                { title: '支付成功！', content: '您的支付成功' },
                { title: '支付失败！', content: '您的本次支付失败，如已支付货款，请等待支付宝/微信自动退款。' }
            ],
            payorderid: ''
        }
    },
    methods: {
        getParamsData() {
            // 支付宝支付成功返回?type=0&charset=UTF-8&out_trade_no=20201228545151108&method=alipay.trade.page.pay.return&total_amount=0.03&sign=m0kGlVldNgcIo7vSYW3wpqhPWIvfz5N3x%2FgmEOKn6b8j0xz7nkmoDQANnJB9KVLLL8j35sLWwdQ5C9PwbNHrqV1rOtcV7KQ%2FCGlImR0MbcRhONz2SkyEPKNFnmcgGtlx0H06FNEvJf18YeOJ3tTR8lYDjc9FGmYITbFCHYkxV6z75%2FPPxRZdd0Uj9kP7xQaoFkI5gO%2FONGheZ4pk%2F9%2Bt%2FucATGCTlKWqZmGSfyJ7v2mTMWsjUgr3hAFluT1PNx3tSq1plqs5D25Y53v2wvAWRXtqHG%2F8W%2BZTHDT1ZHvQqRqN9KiAiWzrE%2FLKyZ%2BxPupRkk6K%2BTTxDlgDl6cLnxqYYQ%3D%3D&trade_no=2020122822001403981443466523&auth_app_id=2021002109669764&version=1.0&app_id=2021002109669764&sign_type=RSA2&seller_id=2088041070330550&timestamp=2020-12-28%2000%3A36%3A40
            console.log('query data', this.$route.query)
            const queryData = this.$route.query
            if (queryData) {
                this.payResultIndex = this.$route.query['type']
                // 微信取payorderid，支付宝返回outTradeNo
                this.payorderid = this.$route.query['payorderid'] || this.$route.query['out_trade_no']
                // 支付流水号
                if (this.payorderid) {
                    this.payResultType[0].content += `，支付流水号：${this.payorderid}`
                }
            }
        }
    },
    created() {
        this.getAccount()
    },
    beforeRouteEnter(to, from, next) {
        // console.log('from, to', from, to)
        next(vm => {
            vm.getParamsData()
        })
    }
}
</script>
<style lang="scss" scoped>
.pay-item {
    position: relative;
    width: 1200px;
    min-height: 60px;
    padding: 20px;
    margin: 10px auto 20px;
    background-color: $white;
    &.mt20 {
        margin-top: 20px;
    }
}
.pay-title {
    margin-bottom: 10px;
    font-size: 18px;
}
.pay-txt {
    color: $light;
}
.pay-price {
    margin-bottom: 10px;
    font-size: 20px;
    color: $red;
}
.pay-txt-strong {
    margin-bottom: 10px;
    font-size: 14px;
}
.pay-price-wrapper {
    position: absolute;
    top: 30px;
    right: 20px;
    font-size: 14px;
    .pay-price {
        padding: 0 10px;
    }
}
.pay-main {
    width: 360px;
    margin: 0 auto;
    text-align: center;
    .btn-primary {
        display: block;
        width: 300px;
        margin: 0 auto;
    }
}
.pay-tips {
    margin-bottom: 15px;
    color: $light;
}
.pay-qrcode {
    @include widthHeight(300px, 300px);
    padding: 10px;
    margin: 0 auto;
    border: 1px solid $gray;
    > img {
        display: block;
        width: 100%;
        height: 100%;
    }
}
.pay-qrcode-tips {
    width: 300px;
    padding: 12px 20px;
    margin: 10px auto;
    line-height: 1.5;
    color: $white;
    background-color: $redlight;
}
.pay-result {
    .pay-txt {
        margin-bottom: 10px;
    }
    .btn-primary {
        width: 182px;
    }
}
.footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
}
</style>
