// 权限相关混用（获取用户信息及检测用户名是否违规）
import { getUserAccount, userExamine } from '@api'
export default {
    data() {
        return {
            userInfo: null, // 用户信息
        }
    },
    // 统一获取用户信息
    // beforeCreate() {
    //     this.getAccount()
    // },
    methods: {
        // status: 1正常、2违规
        async userExamine() {
            const res = await userExamine()
            if (res && res.detail && res.detail.status) {
                // 更新status(用户名是否违规状态
                this.$set(this.userInfo, 'status', res.detail.status)
            }
            // 缓存登录信息
            sessionStorage.setItem('USER_INFO', JSON.stringify(this.userInfo))
        },
        // 获取用户信息
        async getAccount() {
            const res = await getUserAccount()
            if (res && res.detail) {
                // 缓存登录信息
                this.userInfo = Object.assign({}, res.detail)
                this.userExamine()
            }
        },
    }
}
