<template>
    <div class="main pay ft-fixed">
        <simple-header :userInfo="userInfo" />
        <pay-logo />
        <section class="pay-item mt20">
            <h3 class="pay-title">订单提交成功</h3>
            <p class="pay-txt">您的订单提交成功，请尽快付款！订单号：{{ orderId }}</p>
        </section>
        <section class="pay-item">
            <h3 class="pay-title">待支付金额</h3>
            <p class="pay-price"><strong>{{ money }}</strong><i>元</i></p>
            <p v-if="showIntegral" class="pay-txt"><el-checkbox v-model="payKC">使用K点抵扣：{{ isuseIntergal }}</el-checkbox></p>
        </section>
        <section class="pay-item">
            <p class="pay-txt-strong">选择支付方式</p>
            <p class="pay-choise">
                <!-- 2:支付宝；1:微信支付 -->
                <a href="javascript:;" class="pay-choise-item" :class="{ active: payMethod===2}" @click="payMethod=2"><i class="icon icon-zhifubao" /><span class="pay-choise-txt">支付宝支付</span></a>
                <a href="javascript:;" class="pay-choise-item" :class="{ active: payMethod===1 }" @click="payMethod=1"><i class="icon icon-wxzhifu" /><span class="pay-choise-txt">微信支付</span></a>
            </p>
        </section>
        <div ref="qrcodeform" v-show="payMethod===2"></div>
        <div class="btn-wrapper">
            <!-- 2:支付宝；1:微信支付 -->
            <a v-if="payMethod===1" class="btn-primary btn-round" @click.prevent="checkOrderPay(1)">立即支付</a>
            <a v-if="payMethod===2" class="btn-primary btn-round" @click="checkOrderPay(2)">立即支付</a>
            <!-- 支付宝先不检查是否支付 -->
            <!-- <a v-if="payMethod===2" :href="`http://kamisoul.renmnet.com/order/orderPays?type=${payMethod}&orderid=${orderId}&money=${money}`" class="btn-primary btn-round">立即支付</a> -->
        </div>
        <simple-footer />
    </div>
</template>
<script>
import simpleHeader from '@c/simpleHeader.vue'
import simpleFooter from '@c/simpleFooter.vue'
import payLogo from './payLogo.vue'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { getOrderPayStatus, checkOrderStatus, checkOrderEndStatus, orderPays, orderPaye, getUserIntegral, checkOrderType } from '@api/order'
export default {
    name: 'Payment',
    mixins: [ mixin, authMixin ],
    components: {
        simpleHeader,
        simpleFooter,
        payLogo
    },
    data() {
        return {
            payKC: false,
            payMethod: 1, // 1:微信支付；2:支付宝
            orderId: '',
            money: '',
            isuseIntergal: 0,
            showIntegral: true,
            orderstatus: '', // 如果为3，需要追加尾款，调用另一个付尾款接口orderPaye
        }
    },
    methods: {
        async getUserIntegral() {
            const res = await getUserIntegral()
            if (res && res.detail) {
                // 缓存登录信息
                // this.userInfoObj = Object.assign({}, res.detail)
                // console.log('userInfoObj1', this.userInfoObj)
                this.isuseIntergal = res.detail.integral
            }
        },
        async getParamsData() {
            console.log('query data', this.$route.query)
            if (this.$route.query) {
                this.orderId = this.$route.query['orderid']
                // this.money = this.$route.query['money']
                this.orderstatus = Number(this.$route.query['orderstatus'])
            }
            // 检查订单类型
            const res = await checkOrderType({ orderid: JSON.parse(this.orderId)[0] })
            const resInfo = res.detail.status
            if(resInfo.status === 1) {
                this.money = resInfo.paymoney
                this.showIntegral = resInfo.isintegral === 0
                this.getUserIntegral()
            }
            // try {
            //     this.isuseIntergal = JSON.parse(sessionStorage.getItem('USER_INFO')).integral
            // } catch (err) {
            //     console.log(err)
            // }
        },
        // 检查订单是否已经支付type:2,支付宝;1.微信; isuse_intergal(0不使用，1使用)
        async checkOrderPay(type) {
            const params = {
                orderid: this.orderId,
                money: this.money,
                type: this.payMethod,
                // isuse_intergal: this.isuseIntergal
                isuse_intergal: this.payKC ? 1 : 0 // 0不使用，1使用
            }
            // checkOrderEndStatus为付尾款检查: 1 可以正常支付  2001 一分钟内连续产生交易单最多5条  2002 一小时内最多10条   2 已付款 3订单异常
            const res = this.orderstatus === 3 ? await checkOrderEndStatus(params) : await checkOrderStatus(params)
            console.log('checkOrderStatus res', res)
            const resDetail = res.detail
            if (resDetail) {
                if (resDetail.status === 1) { // 可以正常支付
                    this.orderPays(type)
                    // if(type === 1) { // 微信
                    //     this.$router.push({ path: 'payment-method', query: { type: this.payMethod, orderid: this.orderId, orderstatus: this.orderstatus, money: this.money, paykc: this.payKC } })
                    // } else if(type === 2) { // 支付宝
                    //     // this.orderstatus === 3 为尾款支付，需调用orderPaye接口
                    //     const orderPayMethod = this.orderstatus === 3 ? 'orderPaye' : 'orderPays'
                    //     location.href = `http://kamisoul.renmnet.com/order/${orderPayMethod}?type=${this.payMethod}&orderid=${this.orderId}&money=${this.money}&isuse_intergal=${this.payKC ? 1 : 0}`
                    // }
                } else if (resDetail.status === 2) { // 已支付过
                    this.$router.push({ path: '/payment-result', query: { type: 0, payorderid: resDetail.payorderid }})
                } else if(resDetail.status === 2001) { // 一分钟内连续产生交易订单最多5条
                    this.$message.error('一分钟内连续产生交易订单最多5条')
                } else if(resDetail.status === 2002) { // 一小时内连续产生交易订单最多10条
                    this.$message.error('一小时内连续产生交易订单最多10条')                    
                } else if(resDetail.status === 3) { // 订单异常
                    this.$message.error('订单异常')
                }
            }
        },
        // orderid （类似 ["20200610100991028", "20200610100100480"]）, money（总金额），type（1微信2支付宝），isuse_intergal（1 使用用户积分 0不使用）
        async orderPays(type) {
            const params = {
                orderid: this.orderId,
                money: this.money,
                type: this.payMethod,
                // isuse_intergal: this.isuseIntergal
                isuse_intergal: this.payKC ? 1 : 0 // 0不使用，1使用
            }
            // this.orderstatus === 3 为尾款支付，需调用orderPaye接口
            const res = this.orderstatus === 3 ? await orderPaye(params) : await orderPays(params)
            console.log('orderPays res', res)
            if(res) {
                const resDetail = res.detail
                if(resDetail){
                    if (resDetail && resDetail.status === 1 && resDetail.code_url) {
                        // this.getQrCode(resDetail.code_url)
                        // 倒计时
                        // this.countDown()
                        // this.getOrderPayStatus(resDetail.payorderid)
                        console.log('type', type)
                        if(type === 1) { // 微信
                            this.$router.push({ path: 'payment-method', query: { type: this.payMethod, orderid: this.orderId, money: this.money - this.isuseIntergal, codeurl: resDetail.code_url, payorderid: resDetail.payorderid } })
                        }
                    } else if(resDetail.status === 99) {
                        this.$notify({ title: '提示', type: 'error', message: '系统繁忙，请稍后再试', position: 'bottom-right' })
                    } else if(resDetail.status === 1001) {
                        this.$router.push({ path: '/payment-result', query: { type: 0, payorderid: resDetail.payorderid }})
                        this.$notify({ title: '提示', type: 'success', message: '积分抵扣成功', position: 'bottom-right' })
                    } else {
                        this.$notify({ title: '提示', type: 'error', message: '有异常订单不能支付', position: 'bottom-right' })
                    }
                } else { // 支付宝支付时直接返回form字符串没有detail
                    if(type === 2) { // 支付宝
                        // this.orderstatus === 3 为尾款支付，需调用orderPaye接口
                        // const orderPayMethod = this.orderstatus === 3 ? 'orderPaye' : 'orderPays'
                        // location.href = `http://kamisoul.renmnet.com/order/${orderPayMethod}?type=${this.payMethod}&orderid=${this.orderId}&money=${this.money}&isuse_intergal=${this.payKC ? 1 : 0}`
                        this.$refs.qrcodeform.innerHTML = res;
                        let payForm = this.$refs.qrcodeform.querySelector('form');
                        payForm.submit();
                    }
                }
            }
        },
    },
    created() {
        this.getAccount()
    },
    beforeRouteEnter(to, from, next) {
        console.log('from, to', from, to)
        if (from.query) {
            next(vm => {
                vm.getParamsData()
            })
        }
    }

}
</script>
<style lang="scss" scoped>
.pay-logo {
    position: relative;
    height: auto;
}
.pay-item {
    position: relative;
    width: 1200px;
    min-height: 60px;
    padding: 20px;
    margin: 10px auto;
    background-color: $white;
    &.mt20 {
        margin-top: 20px;
    }
}
.pay-title {
    margin-bottom: 10px;
    font-size: 18px;
}
.pay-txt {
    color: $light;
}
.pay-price {
    margin-bottom: 10px;
    font-size: 20px;
    color: $red;
    > i {
        font-size: 14px;
        color: $light;
    }
}
.pay-txt-strong {
    margin-bottom: 10px;
    font-size: 14px;
}
.pay-choise-item {
    @include inlineBlock();
    width: 200px;
    padding: 20px 30px;
    margin-right: 10px;
    border: 1px solid $gray;
    font-size: 18px;
    > .icon {
        margin-right: 10px;
    }
    &.active {
        padding: 18px 28px;
        border: 3px solid $red;
    }
}
.pay-choise-txt {
    vertical-align: -2px;
}
.btn-wrapper {
    width: 1200px;
    padding: 0 20px;
    margin: 10px auto 20px;
    .btn-primary {
        width: 200px;
        cursor: pointer;
    }
}
.ft-fixed {
    .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
    }
}
</style>
